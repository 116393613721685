#ItemSelectPage_Content #ItemRow {
  #MainContainer {
    position: absolute;
    display: flex;
    align-items: center;
    padding-right: 14px;
    margin-left: 16px;
    width: calc(100% - 24px) !important;
    border-bottom: solid rgba(var(--ion-color-light-contrast-rgb), 0.15) 1px;
    cursor: pointer;
    border-left: solid #666 3px;
    height: 45px;
    background-color: var(--ion-color-light);
    z-index: 1;
  }

  .name {
    margin-left: 8px;
    flex: 1;
    align-self: center;
    cursor: pointer;
    color: rgba(var(--ion-color-light-contrast-rgb), 0.5);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
  }

  .notes {
    color: #888;
    padding: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  &.checked {
    .name {
      color: inherit;
    }
  }

  .icon-container {
    display: inline-flex;
    padding-left: 16px;
    height: 100%;
    align-items: center;

    i {
      color: rgba(var(--ion-color-light-contrast-rgb), 0.2) !important;
      min-width: 22px;
    }

    &.checked {
      i {
        color: rgba(var(--ion-color-light-contrast-rgb), 0.6) !important;
      }
    }
  }

  .cart {
    margin-left: -21px;
    margin-top: 0;
    font-size: 16px;
    opacity: 0.6;
    margin-right: 7px;
    color: var(--ion-color-light-contrast) !important;
    margin-left: -16px;
    font-size: 9px;
  }

  .qty-section {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;

    .qty {
      margin-top: -1px;
    }

    .qty-button {
      width: 30px;
      height: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      justify-items: center;
    }

    .minus-button {
      margin-right: 6px;
    }

    .plus-button {
      margin-left: 6px;
    }
  }

  .ion-activatable {
    position: relative;
    overflow: hidden;
  }

  #DeleteContainer {
    z-index: 0;
    position: absolute;
    right: 10px;
    height: 45px;
    display: flex;
    align-items: center;
    background: red;
    padding: 10px;
    color: #fff;
    cursor: pointer;
  }
}
