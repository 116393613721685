#RecipeGroupRow {
  cursor: pointer;
  display: flex;
  padding-left: 6px;
  padding-right: 6px;
  background-color: var(--ion-color-light);

  div.row {
    align-self: center;
    margin-top: 4px;
    height: 41px;
    width: 100%;
    background-color: var(--ion-color-medium);
    display: flex;
    padding-left: 10px;
    border-radius: 4px;
    border: solid rgba(var(--ion-color-light-contrast-rgb), 0.05) 1px;

    .name {
      align-self: center;
      text-transform: capitalize;
    }

    ion-icon {
      height: 100%;
      margin-left: auto;
      margin-right: 10px;
    }
  }
}

html.plt-ios {
  #RecipeGroupRow {
    .name {
      text-transform: capitalize;
    }
  }
}
