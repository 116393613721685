#BoardInvitationAcceptModal {
  --height: 240px; }
  #BoardInvitationAcceptModal ion-card {
    height: 100%;
    margin: 0; }
  #BoardInvitationAcceptModal .ion-page {
    padding-left: 20px !important;
    padding-top: 10px !important;
    padding-right: 20px !important; }
  #BoardInvitationAcceptModal ion-title {
    color: var(--ion-text-color);
    padding-left: 0; }
  #BoardInvitationAcceptModal ion-text {
    color: var(--ion-text-color); }
  #BoardInvitationAcceptModal #buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 20px;
    padding-bottom: 20px; }
  #BoardInvitationAcceptModal #spinner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px; }
  #BoardInvitationAcceptModal ion-list {
    background: none;
    --background: none;
    background: transparent !important;
    background-color: transparent !important;
    padding-left: 30px;
    padding-right: 30px; }
    #BoardInvitationAcceptModal ion-list ion-item {
      --background: transparent !important;
      background: transparent !important; }
