#BoardInvitationNewModal {
  --height: 200px; }
  #BoardInvitationNewModal ion-card {
    height: 100%;
    margin: 0; }
  #BoardInvitationNewModal ion-title {
    padding-inline-end: 0;
    color: var(--ion-text-color);
    text-transform: capitalize;
    font-family: 'Lato-Light';
    font-size: 0.9rem; }
  #BoardInvitationNewModal #buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 10px; }
  #BoardInvitationNewModal ion-list {
    background: none;
    --background: none;
    background: transparent !important;
    background-color: transparent !important;
    padding-left: 30px;
    padding-right: 30px; }
    #BoardInvitationNewModal ion-list ion-item {
      --background: transparent !important;
      background: transparent !important; }
