html.plt-ios.plt-hybrid {
  #ItemsSelectModal {
    ion-toolbar:first-child {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

#ItemsSelectModal {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  
  ion-card {
    margin: 0 !important;
  }

  .modal-wrapper {
    // margin-left: 20px;
    // margin-right: 20px;
  }

  #buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  ion-list {
    background: none;
    --background: none;
    background: transparent !important;
    background-color: transparent !important;
    padding-left: 30px;
    padding-right: 30px;

    ion-item {
      --background: transparent !important;
      background: transparent !important;
    }
  }
}
