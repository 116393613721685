#ListItemsListPage_Content #ListItemRow {
  #MainContainer {
    position: absolute;
    display: flex;
    align-items: center;
    padding-right: 14px;
    margin-left: 16px;
    width: calc(100% - 24px) !important;
    border-bottom: solid rgba(var(--ion-color-light-contrast-rgb), 0.15) 1px;
    cursor: pointer;
    border-left: solid #666 3px;
    height: 45px;
    background-color: var(--ion-color-light);
    z-index: 1;

    img {
      background-color: rgba(var(--ion-color-light-contrast-rgb), 0.1);
      border: 1px solid var(--ion-color-light-shade);
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    &.gotten {
      // background: rgba(var(--ion-color-light-contrast-rgb), .06);
      // background-color: linear-gradient(to bottom, rgba(var(--ion-color-light-contrast-rgb), .06), rgba(var(--ion-color-light-contrast-rgb), .1));
      // background-color: var(--ion-color-light);
      .name {
        color: rgba(var(--ion-color-light-contrast-rgb), 0.4);
      }
    }

    &.gotten-perm {
      // animation: slide-left 0.4s ease;
    }

    .icon-container {
      display: inline-flex;
      padding-left: 16px;
      height: 100%;
      align-items: center;

      i {
        color: rgba(var(--ion-color-light-contrast-rgb), 0.6) !important;
        min-width: 22px;
      }

      &.checked {
        i {
          color: rgba(var(--ion-color-light-contrast-rgb), 0.2) !important;
        }
      }
    }

    .name {
      margin-left: 13px;
      flex: 1;
      align-self: center;
      line-height: 1.8rem;
      cursor: pointer;
    }

    .notes {
      color: #888;
      padding: 0;
      margin-top: -10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      flex: 1;
      height: 29px;
      width: calc(100vw - 62px);
      overflow-y: hidden;
      font-size: 0.8rem !important;
      border-right: solid transparent 24px;
    }

    .ion-activatable {
      position: relative;
      overflow: hidden;
    }

    .has-photo {
      position: absolute;
      right: 20px;
      width: 26px;
      color: rgba(var(--ion-color-light-contrast-rgb), 0.3) !important;
    }

    .storage-file-image {
      position: absolute;
      right: 20px;
      max-width: 44px;
      max-height: 44px;
      background-color: #fff;
      border: 1px solid var(--ion-color-light-shade);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
  }

  #DeleteContainer {
    z-index: 0;
    position: absolute;
    right: 10px;
    height: 45px;
    display: flex;
    align-items: center;
    background: red;
    padding: 10px;
    color: #fff;
    cursor: pointer;
  }
}
