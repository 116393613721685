#LoginPage {
  background-color: var(--ion-color-medium); }
  #LoginPage #LogoContainer {
    display: flex;
    justify-content: center;
    padding: 20px;
    padding-top: calc(15px + var(--ion-safe-area-top));
    max-width: 450px;
    margin: auto; }
    #LoginPage #LogoContainer img {
      width: 65%;
      height: 65%;
      text-align: center; }
  #LoginPage ion-content {
    align-self: center;
    background: transparent !important;
    background-color: transparent !important;
    --background: none; }
  #LoginPage ion-card {
    max-width: 450px;
    margin: auto; }
  #LoginPage ion-card-content {
    padding: 40px;
    padding-bottom: 20px; }
  #LoginPage .or {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 10px;
    display: block; }
  #LoginPage input:-webkit-autofill,
  #LoginPage input:-webkit-autofill:hover,
  #LoginPage input:-webkit-autofill:focus,
  #LoginPage textarea:-webkit-autofill,
  #LoginPage textarea:-webkit-autofill:hover,
  #LoginPage textarea:-webkit-autofill:focus,
  #LoginPage select:-webkit-autofill,
  #LoginPage select:-webkit-autofill:hover,
  #LoginPage select:-webkit-autofill:focus {
    border: none;
    background: none;
    -webkit-box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
    color: var(--ion-text-color) !important; }
  #LoginPage ion-input {
    color: var(--ion-text-color) !important; }
  #LoginPage ion-text {
    text-align: center;
    text-transform: capitalize; }
    #LoginPage ion-text h1 {
      font-size: 1.9rem; }
    #LoginPage ion-text h6 {
      font-size: 0.8rem;
      cursor: pointer; }
  #LoginPage ion-spinner {
    margin-left: 10px; }
  #LoginPage ion-button.login-button {
    --background: #629656; }
  #LoginPage .forgot-password {
    display: flex;
    justify-content: center; }
  #LoginPage .google-button img {
    width: 20px;
    height: 20px; }
  #LoginPage .apple-button {
    margin-top: 20px; }
    #LoginPage .apple-button img {
      width: 20px;
      height: 20px; }
  #LoginPage .error {
    margin-top: 10px;
    display: flex;
    justify-content: center; }
  #LoginPage ion-list {
    background: none;
    --background: none;
    background: transparent !important;
    background-color: transparent !important; }
    #LoginPage ion-list ion-item {
      --background: transparent !important;
      background: transparent !important; }
  #LoginPage html.md ion-label.forced-floating {
    transform: translate3d(0, 50%, 0) scale(0.75) !important; }
  #LoginPage html.plt-ios ion-label.forced-floating {
    transform: translate3d(0, 10%, 0) scale(0.82) !important; }

html.plt-ios #LoginPage ion-text.forgot-password {
  text-transform: capitalize !important; }

html.plt-ios #LoginPage #LogoContainer {
  padding-bottom: 0; }

html.plt-ios #LoginPage .or {
  margin-top: 20px;
  margin-bottom: 0; }

html.plt-ios #LoginPage .google-button,
html.plt-ios #LoginPage .apple-button {
  font-weight: bold;
  border: solid #444 1px;
  border-radius: 2px; }

html.plt-ios #LoginPage ion-item {
  --padding-start: 0;
  --inner-padding-end: 0; }

html.plt-ios #LoginPage ion-card {
  margin-top: 0; }

html.plt-ios #LoginPage ion-card-content {
  padding-top: 10px; }
