html.plt-ios.plt-hybrid {
  #SettingsPage_Header {
    ion-toolbar {
      padding-top: var(--ion-safe-area-top);
    }
  }
}

.split-pane-visible {
  #SettingsPage_Header {
    ion-toolbar {
      --min-height: 46px;
    }
  }
}

#SettingsPage_Content {
  ion-label.ios {
    color: var(--ion-color-light-contrast) !important;
  }
  ion-item {
    cursor: pointer;
  }
  ion-text {
    text-transform: capitalize;
  }
  ion-list-header.md {
    margin-top: 20px;
    ion-label {
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
  .current-board-row {
    font-weight: bold;
  }
}
